import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Instagram, Twitter, LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link for routing

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#444",
        boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.12)",
        padding: "50px 60px 40px",
        color: "white",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        {/* Left Section: Company Info */}
        <Box sx={{ flex: 1, maxWidth: "300px", mb: { xs: 4, md: 0 } }}>
          <Typography variant="h3" sx={{ color: "#ffffff", fontWeight: "normal" }}>
            Digi <span style={{ color: "#5383d3" }}>Flyer</span>
          </Typography>
          <Box sx={{ my: 3 }}>
            <Typography variant="body1">
              <Link to="/" style={{ color: "inherit", textDecoration: "none", marginRight: "8px" }}>Home</Link>
              ·
              <Link to="/about" style={{ color: "inherit", textDecoration: "none", marginLeft: "8px", marginRight: "8px" }}>About</Link>
              ·
              <Link to="/faq" style={{ color: "inherit", textDecoration: "none", marginLeft: "8px", marginRight: "8px" }}>FAQ</Link>
              ·
              <Link to="/contact" style={{ color: "inherit", textDecoration: "none", marginLeft: "8px" }}>Contact</Link>
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ color: "#8f9296", fontSize: "14px" }}>
            DigiFlyer © 2024
          </Typography>

          {/* Social Icons */}
          <Box sx={{ my: 3 }}>
            <a href="https://instagram.com" style={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
              <Instagram sx={{ fontSize: "35px", color: "white", "&:hover": { color: "goldenrod" } }} />
            </a>
            <a href="https://twitter.com" style={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
              <Twitter sx={{ fontSize: "35px", color: "white", "&:hover": { color: "goldenrod" } }} />
            </a>
            <a href="https://linkedin.com" style={{ marginRight: "10px" }} target="_blank" rel="noopener noreferrer">
              <LinkedIn sx={{ fontSize: "35px", color: "white", "&:hover": { color: "goldenrod" } }} />
            </a>
          </Box>
        </Box>

        {/* Right Section: Contact Form */}
        <Box sx={{ flex: 1, maxWidth: "400px", mb: { xs: 4, md: 0 } }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>Contact Us</Typography>
          <form>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              sx={{ backgroundColor: "#1f2022", marginBottom: "15px", borderRadius: "5px" }}
            />
            <TextField
              label="Message"
              variant="filled"
              multiline
              rows={4}
              fullWidth
              sx={{ backgroundColor: "#1f2022", marginBottom: "15px", borderRadius: "5px" }}
            />
            <Button variant="contained" sx={{ backgroundColor: "#33383b", color: "#fff", padding: "12px 50px" }}>
              Send
            </Button>
          </form>
        </Box>
      </Box>

      {/* Additional Links Section */}
      <Box sx={{ marginTop: "40px", textAlign: "center", color: "#8f9296" }}>
        <Typography variant="body2">
          <Link to="/privacy-policy" style={{ color: "inherit", textDecoration: "none", marginRight: "15px" }}>Privacy Policy</Link>·
          <Link to="/terms-of-service" style={{ color: "inherit", textDecoration: "none", marginLeft: "15px" }}>Terms of Service</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
