import React from "react";
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia, IconButton } from "@mui/material";
import { useCart } from "./CartContext";
import Layout from "../components/Layout/Layout";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete"; // Add delete icon for item removal
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const CartPage = () => {
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();

  const handleRemoveItem = (id) => {
    removeFromCart(id);
    toast.success("Item removed from cart!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleUpdateQuantity = (id, quantity) => {
    if (quantity >= 1) {
      updateQuantity(id, quantity);
      toast.info(`Quantity updated to ${quantity}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleClearCart = () => {
    clearCart();
    toast.warn("Cart cleared", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // Calculate the total price of items in the cart
  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  // Generate the cart message for WhatsApp
  const generateWhatsAppMessage = () => {
    let message = "I would like to purchase the following items:\n\n";
    cart.forEach(item => {
      message += `${item.title} - ₹${item.price} x ${item.quantity}\n`;
    });
    message += `\nTotal Price: ₹${totalPrice}\n\nPlease confirm the order.`;
    return encodeURIComponent(message); // URL-encode the message
  };

  // WhatsApp phone number (replace with your own)
  const phoneNumber = "+918871336877"; // Replace with your WhatsApp number (with country code, without '+')

  // WhatsApp link
  const whatsAppLink = `https://wa.me/${phoneNumber}?text=${generateWhatsAppMessage()}`;

  return (
    <Layout>
      <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "0 auto" }}>
        <Typography variant="h3" gutterBottom>Your Cart</Typography>
        {cart.length === 0 ? (
          <Typography variant="h6" color="text.secondary">Your cart is empty.</Typography>
        ) : (
          <Grid container spacing={4}>
            {cart.map((item) => (
              <Grid item xs={12} md={4} key={item.id}>
                <Card sx={{ display: "flex", boxShadow: 3, borderRadius: 2, overflow: "hidden" }}>
                  <CardMedia
                    component="img"
                    image={item.images[0]}
                    alt={item.title}
                    sx={{ width: 150, height: 150, objectFit: "cover" }}
                  />
                  <CardContent sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="body2" color="text.secondary">₹{item.price}</Typography>

                    <Box sx={{ marginTop: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body2">Quantity: </Typography>
                      <IconButton onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)} color="primary">
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body2">{item.quantity}</Typography>
                      <IconButton onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)} color="primary">
                        <AddIcon />
                      </IconButton>
                    </Box>

                    <Box sx={{ marginTop: 2 }}>
                      <IconButton
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveItem(item.id)}
                        sx={{ marginTop: 2, color: "red" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {cart.length > 0 && (
          <Box sx={{ marginTop: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
              <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ padding: "1rem", fontSize: "1rem" }}
              href={whatsAppLink} // Link to WhatsApp
            >
              Buy Now via WhatsApp
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ padding: "1rem", fontSize: "1rem", marginTop: 2 }}
              onClick={handleClearCart}
            >
              Clear Cart
            </Button>
          </Box>
        )}
      </Box>
      
      {/* ToastContainer is necessary to render toasts */}
      <ToastContainer />
    </Layout>
  );
};

export default CartPage;
