// src/pages/FAQ.js
import React from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Layout from "../components/Layout/Layout";

const FAQ = () => {
  return (
    <Layout>
    <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "0 auto" }}>
      <Typography variant="h3" gutterBottom>
        Frequently Asked Questions (FAQ)
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">What is DigiFlyer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            DigiFlyer is an online marketplace for electronics where you can shop for various products, compare prices, and place orders easily.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h6">How do I make a purchase?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            To make a purchase, browse through our products, select your desired item, and proceed to checkout to place your order.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography variant="h6">How can I contact customer support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            You can contact our customer support team by visiting the Contact Us page and filling out the form, or by emailing support@digiflyer.com.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Add more FAQs as needed */}
    </Box>
    </Layout>  );
};

export default FAQ;
