import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Button, Grid, Container, Tabs, Tab, ListItem, List, ListItemText } from "@mui/material";
import { products } from "../data/products"; // Import products data
import Layout from "../components/Layout/Layout";
import { Carousel } from "react-responsive-carousel";
import { useCart } from "./CartContext"; // Import cart context
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast, ToastContainer } from "react-toastify"; // Correct import of ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for toastify

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { addToCart } = useCart(); // Use the addToCart function from context

  useEffect(() => {
    const productId = parseInt(id);
    const selectedProduct = products.find((product) => product.id === productId);
    setProduct(selectedProduct);
  }, [id]);

  if (!product) {
    return <Typography variant="h6" align="center">Product not found</Typography>;
  }

  // Calculate the discounted price if a discount exists
  const discountedPrice = product.discount
    ? product.price - (product.price * (parseInt(product.discount) / 100))
    : product.price;

  const handleAddToCart = () => {
    addToCart(product);
    // Show toast notification
    toast.success(`${product.title} has been added to your cart!`, {
      position: "top-right", // You can adjust the position as needed
      autoClose: 3000, // The toast will disappear after 3 seconds
      hideProgressBar: false, // Optional: Show progress bar
      closeOnClick: true, // Close on click
      pauseOnHover: true, // Pause on hover
      draggable: true, // Allow dragging
      progress: undefined, // You can set progress if needed
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Layout>
      <Container sx={{ maxWidth: "1200px", margin: "0 auto", padding: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Carousel dynamicHeight>
              {product.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={product.title} />
                </div>
              ))}
            </Carousel>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>{product.title}</Typography>
            <Typography variant="h5" color="text.secondary" paragraph>{product.description}</Typography>

            {/* Show Price and Discount */}
            <Typography variant="h4" color="primary" gutterBottom>
              {product.discount ? (
                <>
                  <span style={{ textDecoration: "line-through", marginRight: "10px" }}>
                    ₹{product.price}
                  </span>
                  <span style={{ color: "green" }}>
                    ₹{discountedPrice.toFixed(2)} (Discounted)
                  </span>
                </>
              ) : (
                `Price: ₹${product.price}`
              )}
            </Typography>

            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="product details" sx={{ marginBottom: 2 }}>
              <Tab label="Features" />
              <Tab label="Specifications" />
              <Tab label="Reviews" />
            </Tabs>

            {/* Features Section */}
            {selectedTab === 0 && (
              <Box>
                <Typography variant="h6" gutterBottom>Features:</Typography>
                <ul>
                  {product.features.map((feature, index) => (
                    <li key={index}>
                      <Typography variant="body1">{feature}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            )}

            {/* Specifications Section */}
            {selectedTab === 1 && (
              <Box>
                <Typography variant="h6" gutterBottom>Specifications:</Typography>
                <List>
                  {Object.entries(product.specifications).map(([key, value], index) => (
                    <ListItem key={index}>
                      <ListItemText primary={key} secondary={value} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {/* Reviews Section */}
            {selectedTab === 2 && product.reviews && (
              <Box>
                <Typography variant="h6" gutterBottom>Reviews:</Typography>
                {product.reviews.length > 0 ? (
                  product.reviews.map((review, index) => (
                    <Box key={index} sx={{ marginBottom: 2, borderBottom: "1px solid #ddd", paddingBottom: 2 }}>
                      <Typography variant="h6">{review.username}</Typography>
                      <Typography variant="body2">Rating: {review.rating} / 5</Typography>
                      <Typography variant="body1">{review.comment}</Typography>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1">No reviews yet</Typography>
                )}
              </Box>
            )}

            {/* Add to Cart Button */}
            <Box sx={{ marginTop: 2 }}>
              <Button variant="contained" color="primary" sx={{ padding: "10px 20px" }} onClick={handleAddToCart}>
                Add to Cart
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      
      {/* ToastContainer is necessary to render toasts */}
      <ToastContainer />
    </Layout>
  );
};

export default ProductDetail;
