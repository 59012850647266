import React, { useState } from "react";
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography, Badge } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo.svg";
import CartIcon from "@mui/icons-material/ShoppingCart"; // Import the shopping cart icon
import "../../styles/HeaderStyles.css";
import FloatingWhatsappButton from "../../pages/FloatingWhatsappButton";
import { useCart } from "../../pages/CartContext"; // Import the cart context

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { cart } = useCart(); // Get the cart state from the context

  // Handle menu click (toggle drawer)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Menu drawer structure
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography color={"goldenrod"} variant="h6" component="div" sx={{ flexGrow: 1, my: 2 }}>
        <NavLink to="/">
          <img src={Logo} alt="logo" height={"70"} width="200" />
        </NavLink>
      </Typography>
      <Divider />
      <List>
        <ListItem button component={NavLink} to={"/"} activeClassName="active">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={NavLink} to={"/products"}>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem button component={NavLink} to={"/about"}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={NavLink} to={"/contact"}>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component={NavLink} to={"/CartPage"}>
          <ListItemText primary="Cart" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: "black" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                mr: 2,
                display: { sm: "none" }, // Show only on small screens
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography color={"goldenrod"} variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <NavLink to="/">
                <img src={Logo} alt="logo" height={"70"} width="250" />
              </NavLink>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {/* Desktop Navigation Menu */}
              <List sx={{ display: "flex" }}>
                <ListItem button component={NavLink} to={"/"} activeClassName="active">
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem button component={NavLink} to={"/products"}>
                  <ListItemText primary="Products" />
                </ListItem>
                <ListItem button component={NavLink} to={"/about"}>
                  <ListItemText primary="About" />
                </ListItem>
                <ListItem button component={NavLink} to={"/contact"}>
                  <ListItemText primary="Contact" />
                </ListItem>

                {/* Cart Item Count and Cart Icon */}
                <ListItem button component={NavLink} to={"/CartPage"}>
                  <Badge
                    badgeContent={cart.length} // Show the cart length
                    color="secondary"
                    max={99} // Set max limit for display
                  >
                    <CartIcon sx={{ color: "white" }} />
                  </Badge>
                  <ListItemText primary="Cart" sx={{ marginLeft: 1 }} />
                </ListItem>
              </List>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Drawer for Mobile */}
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "240px",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
      <FloatingWhatsappButton />
    </>
  );
};

export default Header;
