import React from "react";
import Layout from "./../components/Layout/Layout";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import { Box, Typography, TextField, Button, Grid, Card, CardContent } from "@mui/material";

const Contact = () => {
  return (
    <Layout>
      {/* Page Introduction Section */}
      <Box sx={{ my: 5, textAlign: "center", px: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "#333" }}>
          Get In Touch with Us
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "700px", margin: "0 auto", mt: 2, color: "#777" }}>
          We're here to help you! If you have any questions regarding our home appliances or need support, please feel free to contact us. Our team is happy to assist you!
        </Typography>
      </Box>

      {/* Contact Details Section */}
      <Box sx={{ my: 5, display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
        <Card sx={{ width: "250px", mb: 3 }}>
          <CardContent sx={{ textAlign: "center" }}>
            <SupportAgentIcon sx={{ fontSize: 50, color: "#FF6347" }} />
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Toll-Free Support
            </Typography>
            <Typography sx={{ color: "#777" }}>
              +91 88713 36877            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "250px", mb: 3 }}>
          <CardContent sx={{ textAlign: "center" }}>
            <MailIcon sx={{ fontSize: 50, color: "#1E90FF" }} />
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Email Us
            </Typography>
            <Typography sx={{ color: "#777" }}>
              support@digiFlyer.com
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "250px", mb: 3 }}>
          <CardContent sx={{ textAlign: "center" }}>
            <CallIcon sx={{ fontSize: 50, color: "#32CD32" }} />
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Customer Service
            </Typography>
            <Typography sx={{ color: "#777" }}>
              +91 88713 36877
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Address Section */}
      <Box sx={{ my: 5, textAlign: "center" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>Our Address</Typography>
        <Typography variant="body1" sx={{ color: "#777", mt: 2 }}>
          570 M.g. Road, Unit No G 28, City Center, Indore Uchchanyayalay, Indore, Indore, Madhya Pradesh, India, 452001.
        </Typography>
      </Box>

      {/* Form and Map Section */}
      <Grid container spacing={4} sx={{ my: 5 }}>
        {/* Form Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3, textAlign: "center" }}>
            Send Us a Message
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              backgroundColor: "#f9f9f9",
              padding: 4,
              borderRadius: 2,
              boxShadow: 2,
              marginTop: 3,
              "& .MuiTextField-root": { width: "100%" },
            }}
          >
            <TextField
              label="Your Name"
              variant="outlined"
              required
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              label="Your Email"
              variant="outlined"
              type="email"
              required
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              label="Subject"
              variant="outlined"
              required
              sx={{ backgroundColor: "white" }}
            />
            <TextField
              label="Your Message"
              variant="outlined"
              multiline
              rows={5}
              required
              sx={{ backgroundColor: "white" }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                alignSelf: "flex-end",
                padding: "10px 25px",
                fontWeight: "bold",
                borderRadius: 20,
                mt: 2,
                backgroundColor: "#FF6347",
                "&:hover": { backgroundColor: "#FF4500" },
              }}
            >
              Send Message
            </Button>
          </Box>
        </Grid>

        {/* Map Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>Find Us on the Map</Typography>
          <Box sx={{ maxWidth: "100%", height: "400px", marginTop: 3 }}>
            {/* Embed a Google Map iframe here */}
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487.3791441599225!2d75.87443726954785!3d22.720952544876898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd15b08e37ad%3A0x44d452c7fc87e679!2s570%2C%20Mahatma%20Gandhi%20Rd%2C%20Sanghi%20Colony%2C%20South%20Tukoganj%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1733830602308!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Contact;
