export const products = [
    {
      id: 1, 
      title: "Smart LED TV",
      description:
        "A high-definition smart LED TV that offers superior picture quality with access to streaming platforms, gaming apps, and more.",
      images: [
        "https://via.placeholder.com/500x300?text=Smart+LED+TV+Image1",
        "https://via.placeholder.com/500x300?text=Smart+LED+TV+Image2",
        "https://via.placeholder.com/500x300?text=Smart+LED+TV+Image3",
      ],
      features: [
        "4K Resolution",
        "Smart Streaming Apps",
        "Built-in Speakers",
        "HDR Support",
      ],
      specifications: {
        "Screen Size": "55 inches",
        "Resolution": "3840 x 2160",
        "Ports": "3 HDMI, 2 USB",
        "Operating System": "Android TV",
        "Wi-Fi": "Yes",
      },
      price: 500,
      discount: "10%",  // Added discount
      category: "Television", 
      link: "/smart-led-tv",
    },
    {
      id: 2, 
      title: "Non-Smart LED TV",
      description:
        "A traditional LED TV offering high-definition resolution. Perfect for users who prefer simplicity.",
      images: [
        "https://via.placeholder.com/500x300?text=Non-Smart+LED+TV+Image1",
        "https://via.placeholder.com/500x300?text=Non-Smart+LED+TV+Image2",
      ],
      features: [
        "Full HD Resolution",
        "Energy Efficient",
        "Lightweight Design",
      ],
      specifications: {
        "Screen Size": "32 inches",
        "Resolution": "1920 x 1080",
        "Ports": "2 HDMI, 1 USB",
        "Sound": "Stereo",
      },
      price: 250,
      discount: "5%",  // Added discount
      category: "Television", 
      link: "/non-smart-led-tv",
    },
    {
      id: 3, 
      title: "Cooler",
      description:
        "An efficient air cooler with eco-friendly and energy-saving features for homes, offices, and outdoor spaces.",
      images: [
        "https://via.placeholder.com/500x300?text=Cooler+Image1",
        "https://via.placeholder.com/500x300?text=Cooler+Image2",
      ],
      features: [
        "Energy-Efficient",
        "Multiple Speed Settings",
        "Eco-Friendly",
        "Compact Design",
      ],
      specifications: {
        "Capacity": "40 Liters",
        "Power": "150 Watts",
        "Dimensions": "45 x 45 x 90 cm",
        "Cooling Area": "Up to 500 sq. ft.",
      },
      price: 150,
      discount: "15%",  // Added discount
      category: "Home Appliances", 
      link: "/cooler",
    },
    {
      id: 4, 
      title: "Static Signage Board",
      description:
        "A reliable, static LED signage board ideal for advertisements and promotions with high brightness for visibility.",
      images: [
        "https://via.placeholder.com/500x300?text=Signage+Board+Image1",
        "https://via.placeholder.com/500x300?text=Signage+Board+Image2",
      ],
      features: [
        "High Brightness",
        "Durable and Weather-Proof",
        "Perfect for Indoor and Outdoor Use",
        "Energy Efficient",
      ],
      specifications: {
        "Dimensions": "50 x 30 inches",
        "Brightness": "1200 NITS",
        "Power Consumption": "80W",
        "Material": "Aluminum Frame",
      },
      price: 300,
      discount: "12%",  // Added discount
      category: "Signage", 
      link: "/static-signage-board",
    },
    // New Products
    {
      id: 5, 
      title: "32-inch LED TV",
      description:
        "A compact LED TV with Full HD resolution, ideal for smaller rooms or as a second TV. Offers great picture quality and energy efficiency.",
      images: [
        "https://via.placeholder.com/500x300?text=32-inch+LED+TV+Image1",
        "https://via.placeholder.com/500x300?text=32-inch+LED+TV+Image2",
      ],
      features: [
        "Full HD Resolution",
        "Energy Efficient",
        "Compact Size",
        "Slim Design",
      ],
      specifications: {
        "Screen Size": "32 inches",
        "Resolution": "1920 x 1080",
        "Ports": "2 HDMI, 1 USB",
        "Sound": "Stereo",
      },
      price: 180,
      discount: "8%",  // Added discount
      category: "Television", 
      link: "/32-inch-led-tv",
    },
    {
      id: 6, 
      title: "24-inch LED TV",
      description:
        "A small, budget-friendly LED TV, perfect for bedrooms, kitchens, or offices. Enjoy Full HD content with clear picture quality.",
      images: [
        "https://via.placeholder.com/500x300?text=24-inch+LED+TV+Image1",
        "https://via.placeholder.com/500x300?text=24-inch+LED+TV+Image2",
      ],
      features: [
        "Full HD Resolution",
        "Space-Saving Design",
        "Budget-Friendly",
        "Lightweight",
      ],
      specifications: {
        "Screen Size": "24 inches",
        "Resolution": "1920 x 1080",
        "Ports": "1 HDMI, 1 USB",
        "Sound": "Stereo",
      },
      price: 130,
      discount: "10%",  // Added discount
      category: "Television", 
      link: "/24-inch-led-tv",
    },
    {
      id: 7, 
      title: "43-inch LED TV",
      description:
        "A mid-size LED TV offering Full HD resolution and a sleek, modern design. Perfect for the living room or larger spaces.",
      images: [
        "https://via.placeholder.com/500x300?text=43-inch+LED+TV+Image1",
        "https://via.placeholder.com/500x300?text=43-inch+LED+TV+Image2",
      ],
      features: [
        "Full HD Resolution",
        "Slim Design",
        "Energy Efficient",
        "Enhanced Sound Quality",
      ],
      specifications: {
        "Screen Size": "43 inches",
        "Resolution": "1920 x 1080",
        "Ports": "3 HDMI, 2 USB",
        "Sound": "Stereo",
      },
      price: 350,
      discount: "6%",  // Added discount
      category: "Television", 
      link: "/43-inch-led-tv",
    },
  ];
  