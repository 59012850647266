// src/pages/TermsOfService.js
import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../components/Layout/Layout";

const TermsOfService = () => {
  return (
    <Layout>
    <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "0 auto" }}>
      <Typography variant="h3" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to DigiFlyer. By using our services, you agree to comply with the following terms and conditions.
      </Typography>
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing or using our website, you agree to be bound by these Terms of Service and any other applicable laws or regulations.
      </Typography>
      <Typography variant="h6">
        2. Use of Services
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use our services in accordance with applicable laws and regulations and in a way that does not infringe on the rights of others.
      </Typography>
      <Typography variant="h6">
        3. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        DigiFlyer will not be liable for any damages arising from the use or inability to use our website or services.
      </Typography>
      {/* Add more sections as needed */}
    </Box>
    </Layout>
  );
};

export default TermsOfService;
