import React from 'react';
import { Box, IconButton } from '@mui/material';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp Icon from react-icons

const FloatingWhatsappButton = () => {
  const whatsappNumber = '+918871336877'; // Replace with your WhatsApp number
  const message = 'Hello, I need help!'; // Customize your message

  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px', // Adjust position from the bottom
        right: '20px',  // Adjust position from the right
        zIndex: 1000,   // Ensure it's above other elements
      }}
    >
      <IconButton
        color="primary"
        component="a"
        href={whatsappUrl}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Security best practice
        sx={{
          backgroundColor: '#25D366', // WhatsApp color
          padding: '15px',
          borderRadius: '50%',
          boxShadow: 3,
          '&:hover': {
            backgroundColor: '#128C7E', // Hover effect color
          }
        }}
      >
        <FaWhatsapp size={30} color="white" />
      </IconButton>
    </Box>
  );
};

export default FloatingWhatsappButton;
