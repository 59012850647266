// src/pages/PrivacyPolicy.js
import React from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
    <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "0 auto" }}>
      <Typography variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        At DigiFlyer, we value your privacy. This privacy policy outlines how we collect, use, and protect your personal information when you use our services.
      </Typography>
      <Typography variant="h6" sx={{ marginTop: "1rem" }}>
        1. Information Collection
      </Typography>
      <Typography variant="body1" paragraph>
        We collect information from you when you register on our site, place an order, subscribe to our newsletter, or interact with our website.
      </Typography>
      <Typography variant="h6">
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use the information we collect to provide you with services, process your orders, and improve your experience with DigiFlyer.
      </Typography>
      <Typography variant="h6">
        3. Information Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter your personal data.
      </Typography>
      {/* Add more sections as needed */}
    </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
